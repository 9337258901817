<template>
  <div>
    <v-breadcrumbs class="py-2" :items="breadcrumbs">
      <template #item="{ item }">
        <router-link :to="item.href" class="v-breadcrumbs__item">
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>
    <v-card outlined class="mt-1">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span v-if="record._id">
          {{ record.key }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row v-if="record._id" class="mt-3" dense>
          <v-col cols="12" md="6">
            <div>{{ $t("Project") }}</div>
            <div class="text-h3">{{ record.project }}</div>
          </v-col>
          <v-col cols="12" md="6">
            <div>{{ $t("Key") }}</div>
            <div class="text-h3">{{ record.key }}</div>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Summary") }}</v-subheader>
          </v-col>
          <v-col cols="12" class="mb-2">
            {{ record.summary }}
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Issue type") }}</div>
            <div class="text-h3">{{ record.issue_type }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Status") }}</div>
            <div class="text-h3">{{ record.bug_status }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Priority") }}</div>
            <div class="text-h3">{{ record.priority }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Resolution") }}</div>
            <div class="text-h3">{{ record.bug_resolution }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Assignee") }}</div>
            <div class="text-h3">{{ record.assignee }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Reporter") }}</div>
            <div class="text-h3">{{ record.reporter }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Created") }}</div>
            <div class="text-h3">{{ record.created }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Updated") }}</div>
            <div class="text-h3">{{ record.updated }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Resolved") }}</div>
            <div class="text-h3">{{ record.resolved }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Affects versions") }}</div>
            <div class="text-h3">{{ record.affects_versions }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Fix versions") }}</div>
            <div class="text-h3">{{ record.fix_versions }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Components") }}</div>
            <div class="text-h3">{{ record.components }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Due date") }}</div>
            <div class="text-h3">{{ record.due_date }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Votes") }}</div>
            <div class="text-h3">{{ record.votes }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Watchers") }}</div>
            <div class="text-h3">{{ record.watchers }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Images") }}</div>
            <div class="text-h3">{{ record.images }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Original estimate") }}</div>
            <div class="text-h3">{{ record.original_estimate }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Remaining estimate") }}</div>
            <div class="text-h3">{{ record.remaining_estimate }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Time spent") }}</div>
            <div class="text-h3">{{ record.time_spent }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Work ratio") }}</div>
            <div class="text-h3">{{ record.work_ratio }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Sub-tasks") }}</div>
            <div class="text-h3">{{ record.sub_tasks }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Linked issues") }}</div>
            <div class="text-h3">{{ record.linked_issues }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Environment") }}</div>
            <div class="text-h3">{{ record.environment }}</div>
          </v-col>
          <v-col v-if="record.description" cols="12">
            <v-subheader>{{ $t("Description") }}</v-subheader>
          </v-col>
          <v-col v-if="record.description" cols="12" class="mb-2">
            {{ record.description }}
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Security level") }}</div>
            <div class="text-h3">{{ record.security_level }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Progress") }}</div>
            <div class="text-h3">{{ record.progress }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Total progress") }}</div>
            <div class="text-h3">{{ record.total_progress }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Total time spent") }}</div>
            <div class="text-h3">{{ record.total_time_spent }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Total remaining estimate") }}</div>
            <div class="text-h3">{{ record.total_remaining_estimate }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Total original estimate") }}</div>
            <div class="text-h3">{{ record.total_original_estimate }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Labels") }}</div>
            <div class="text-h3">{{ record.labels }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Epic theme") }}</div>
            <div class="text-h3">{{ record.epic_theme }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("External issue ID") }}</div>
            <div class="text-h3">{{ record.external_issue_id }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Flagged") }}</div>
            <div class="text-h3">{{ record.flagged }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Global rank") }}</div>
            <div class="text-h3">{{ record.global_rank }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("The one") }}</div>
            <div class="text-h3">{{ record.the_one }}</div>
          </v-col>
          <v-col v-if="record.release_notes" cols="12">
            <v-subheader>{{ $t("Release notes") }}</v-subheader>
          </v-col>
          <v-col v-if="record.release_notes" cols="12" class="mb-2">
            {{ record.release_notes }}
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Epic link") }}</div>
            <div class="text-h3">{{ record.epic_link }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Rank") }}</div>
            <div class="text-h3">{{ record.rank }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Additional information") }}</div>
            <div class="text-h3">{{ record.additional_information }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("External ID") }}</div>
            <div class="text-h3">{{ record.external_id }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Sprint") }}</div>
            <div class="text-h3">{{ record.sprint }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("Wersja docelowa") }}</div>
            <div class="text-h3">{{ record.wersja_docelowa }}</div>
          </v-col>
          <v-col cols="12" md="4">
            <div>{{ $t("External issue URL") }}</div>
            <div class="text-h3">{{ record.external_issue_url }}</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      id: "",
      record: {},
      loading: true,
      path: "",
      render: 1,

      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Applications bugs",
          disabled: false,
          href: "/software-bugs"
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "ApplicationsBug") {
        this.id = this.$route.params.pathMatch
        this.getRecords()
      }
    },
    getRecords() {
      axios
        .get('nvd_applications_bugs?where={"key":"' + this.id + '"}')
        .then(response => {
          this.record = response.data._items[0]
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
